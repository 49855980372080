import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Group`}</h1>
    <p>{`This knowledge base uses the word `}<a parentName="p" {...{
        "href": "/group",
        "title": "group"
      }}>{`group`}</a>{` to refer to subsets of a `}<a parentName="p" {...{
        "href": "/participant",
        "title": "participant"
      }}>{`participant`}</a>{` body, and often implicitly as shorthand for an `}<a parentName="p" {...{
        "href": "/opinion-group",
        "title": "opinion group"
      }}>{`opinion group`}</a>{` in the conversation (sociological groups, if you will).
For more see:`}</p>
    <ul>
      <li parentName="ul">{``}<a parentName="li" {...{
          "href": "/opinion-groups",
          "title": "opinion groups"
        }}>{`opinion groups`}</a>{``}</li>
    </ul>
    <p>{`Note: There are mathematical constructs in abstract algebra called groups, which aren't presently discussed much in this knowledge base.
As needed, we'll specifically call these `}<a parentName="p" {...{
        "href": "/algebraic-group",
        "title": "algebraic group"
      }}>{`algebraic group`}</a>{`s.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      